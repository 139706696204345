

/////   STYLES
/// 
/// 

.experience-card, .education-card {
  width: 100%;  // Full width by default for mobile
  max-width: 600px;  // Set a max width for larger screens
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  //background-color: $card-bg-color;
  border-radius: 8px;
  
  border-style: solid;
  border-width: 1px;

  h4 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
      //color: $primary-color;
  }

  p {
      margin-bottom: 0.5rem;
      //color: $text-color;
      font-size: 1rem;
  }

  ul {
      padding-left: 2rem;
      
      li {
          font-size: 1rem;
          //color: $secondary-color;
          line-height: 1.5;
      }
  }

 

  // Add responsive styles for mobile
  @media (max-width: 576px) {
    padding: 1rem; // Reduce padding for smaller screens
    h4 {
        font-size: 1.25rem; // Smaller font size for titles
    }
    p, li {
        font-size: 0.9rem;  // Smaller font size for paragraphs and list items
    }
    ul {
        padding-left: 1.5rem;  // Reduce padding for bullets
    }
  }

  @media (min-width: 768px) {
    // For larger screens, maintain the default behavior
    padding: 1.5rem;
  }
}


h1, h2, h3, h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}



.notification {
  & + .notification {
    margin-bottom: 10px;
  }}

.notification-stack {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.dashboard {
  display: flex;

}

.manager-header{

  .button-container{
    display: flex;
    align-items: center;
    margin-top: 5vh;
  }

}

// .sidebar {
//   flex-basis: 25vw; /* or whatever width you prefer */
//   background-color: #f8f9fa; /* light gray background */
//   border-right: 1px solid #dee2e6; /* light border */
//   overflow-y: auto; /* in case the content exceeds the viewport height */

// }

// .main-content {
//   flex-grow: 1; /* takes up the remaining space */
//   overflow-y: auto; /* in case the content exceeds the viewport height */
// }

.navigation {
  border-width: 0px !important;
  width:100vw;
  flex-shrink:0;
  .current{
    background-color:#445069;
    color:#EFF0F0;
   
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
 

}
.navbar {
  display: flex;
  justify-content: space-between;
  padding: 20px!important;

  .logo {
    font-size: 1.5em;
    padding: 10;
  }

  .nav-links {
    display: flex;
    gap: 1em;

    @media (max-width: 768px) {
      display: none;
    }
  }
  .nav-link {
    text-decoration: none;

  }
  
}



.manager-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  margin: auto;
  margin-top: 5vh;
}



.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.login-form {
  max-width: 600px;
  // Other styles...
}


.flex-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  flex-basis: 250px;
  // background-color: #f8f9fa;
  // border-right: 1px solid #dee2e6;
  overflow-y: auto;
  min-height:100vh;
}

.main-content {
  flex-grow: 1;
  
  min-height: 100vh;
}

.mobile-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.mobile-navbar {
  flex-shrink: 0; /* Ensure navbar doesn't shrink */
}

.mobile-content {
  flex-grow: 1;
  
  min-height: 100vh;
}

.custom-accordion .accordion-header {
  cursor: pointer;
}

.custom-accordion .accordion-body {
  padding: 10px;
  border: 0px solid #e0e0e0;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.loading-container i {
  font-size: 5em;
  margin-bottom: 20px;
  
}

.loading-container p {
  font-size: 1.5em;
}





//////COLOR PALETTES
/// --DARK--
/// primary: bright
/// secondary: pastel
/// 
/// label: pastel dark
/// container: light
/// item: pastel normal
/// 
/// header: pastel dark
/// background: pastel darkest
/// footer: pastel normal
/// 
/// font: dark
/// font-2: slightly dark
/// link: bright
/// link-2: light



$palette-d-forest:(
  primary: #c45c45, //+
  secondary: #f0ecf7,
  active: #000,
  disabled: #000,

  //container
  label: #1f2c2b, //+
  container: #030707, //+
  item: #214f4a,  //+
 
  //body
  header: #011302,  //+
  background: #030707,  //+  1F4743 091716
  footer: #3D8F87,  //+
  // 011502
  //fonts
  font: #011302,  //+ BBEDF2
  font-2: #BBEDF2,  // 011302
  link: #bd6c5a,
  link-2: #171a1d,
);

$palette-d-tangerine:(
  primary: #d75433, //+
  secondary: #f0ecf7, //
  active: #000,
  disabled: #000,

  //container
  label: #443730,   //+
  container: #060100, //+ ce7909 883203
  item: #734227,
  //f09e05
  //body
  header: #251104,    //+
  background: #060100,  //+ 651501
  footer: #FAAF4C,
  //1f0e03
  //fonts
  font: #251104,  //+
  font-2: #FFEEC2,  //+
  link: #df6243,
  link-2: #171a1d,
);         

$palette-d-arctic:(
  primary: #C96A16,   
  secondary: #f0ecf7,    
  active: #000,
  disabled: #000,

  //container
  label: #183741, // 156373
  container: #060c0d, //bccdd1
  item: #38545c,
 
  //body
  header: #11272E,
  background: #060c0d,  // 1E4148
  footer: #2a2e2f,
  
  //fonts
  font: #323A3C,  //323A3C
  font-2: #DFE0E2,  //DFE0E2
  link: #23B5D3,
  link-2: #1c1d1e,

);

$palette-d-grape:(  //NEEDS SOME COLORS
  primary: #23B5D3,   //+
  secondary: #f0ecf7,    
  active: #000,
  disabled: #000,

  //container
  label: #321832,   //+
  container: #0a0411, //+  4e1e56
  item: #5b394a, //+
 
  header: #190019,  //+
  background: #090310, //+  2b124c
  footer: #2c1a24,  //+
  
  //fonts
  font: #190019, //+
  font-2: #FBE4D8,  //+
  link: #23B5D3,   //+
  link-2: #0a0411,
  );

  $palette-d-peach:(
  primary: #c98f5f,   //+
  secondary: #f0ecf7,    

  //container
  label: #2b293c, //+
  container: #020104, //+
  item: #55202c,  //+
 
  //body
  header: #1d1a39,  //+
  background: #020104,  //+ 34133F 41184E 190E2E
  footer: #843344,    //+
  
  //fonts
  font: #1d1a39,  //+
  font-2: #e8dad9,  //+
  link: #c98f5f,  //+
  link-2: #1b1e21,
);

$palette-d-coconut:(
  primary: #69554F,   //+
  secondary: #F0F7F4,    
  active: #000,
  disabled: #000,

  //container
  label: #43373f, //+ #FFE8C9  70ABAF
  container: #0e1010, //+  ece5c5
  item: #202426,  //+ #5B6706 99E1D9
 
  //body
  header: #32292f,   //+
  background: #0a0c0c,  //+ 4B5857  272e2e
  footer: #4B5857,    //+
  
  //fonts
  font: #32292f,   //+
  font-2: #F0F7F4,   //+
  link: #b9f4da,    //+ #171717  69554F
  link-2: #0e1010,
);



//                        LIGHT THEMES
$palette-l-forest:(
  primary: #C24E34, //+
  secondary: #f0ecf7,
  active: #000,
  disabled: #000,

  //container
  label: #213121, //+ 13675C
  container: #edf3f3, //+ CFE2CA
  item: #57cdc1,  //+ 49ABA2
 
  //body
  header: #011302,  //+ 011302
  background: #edf3f3,  //+ 1F4743
  footer: #538a84,  //+ 3D8F87
  // 011502
  //fonts
  font: #BBEDF2,  //+ BBEDF2
  font-2: #011302, // 011302
  link: #C24E34,  // C24E34
  link-2: #1d2124, //8998A9
);

$palette-l-tangerine:(
  primary: #DC2F02, //+
  secondary: #f0ecf7,
  active: #000,
  disabled: #000,

  //container
  label: #645348,   //+  F09E05
  container: #efeadf, //+  FBC174
  item: #e7c88f,
  //f09e05
  //body
  header: #251104,    //+
  background: #efeadf,  //+  651501
  footer: #FAAF4C,  //FAAF4C
  //1f0e03
  //fonts
  font: #FFEEC2,  //+  251104
  font-2: #251104,  //+  FFEEC2
  link: #DC2F02,
  link-2: #1c1f22,
);         

$palette-l-arctic:(
  primary: #C96A16,   
  secondary: #f0ecf7,    
  active: #000,
  disabled: #000,

  //container
  label: #193b45, // 156373
  container: #e8ecf2, // bccdd1
  item: #75ABBC,  //
 
  //body
  header: #11272E,    //  11272E
  background: #e8ecf2,  // 1E4148
  footer: #8998a9,    // 8998a9
  
  //fonts
  font: #DFE0E2,  // 323A3C
  font-2: #323A3C,    // DFE0E2
  link: #115562,
  link-2: #151719,

);

$palette-l-grape:(  //NEEDS SOME COLORS
  primary: #23B5D3,   //+
  secondary: #f0ecf7,    
  active: #000,
  disabled: #000,

  //container
  label: #3f2a3f,   //+
  container: #faf6f4, //+ DFB6B2
  item: #a57e92, //+
 
  //body
  header: #190019,  //+
  background: #faf6f4, //+ 2b124c
  footer: #854F6C,  //+ 854F6C
  
  //fonts
  font: #FBE4D8, //+ 190019
  font-2: #190019,  //+
  link: #0e5360,   //+
  link-2: #181a1d,
  );

  $palette-l-peach:(
  primary: #F39F5A,   //+
  secondary: #f0ecf7,    

  //container
  label: rgb(43, 38, 86), //+  592140
  container: #f0ecf7, //+
  item: #b17c87,  //+
 
  //body
  header: #1d1a39,  //+
  background: #f0ecf7,  //+ 41184E
  footer: #843344,    //+
  
  //fonts
  font: #F0F7F4,  //+
  font-2: #1d1a39,  //+
  link: #B0455C,  //+  F39F5A
  link-2: #1b1a1d,
);

$palette-l-coconut:(
  primary: #69554F,   //+
  secondary: #f2f4f3,    
  active: #000,
  disabled: #000,

  //container
  label: #473a42, //+  70ABAF
  container: #f2f4f3, //+ C5ECE7
  item: #99E1D9,  //+
 
  //body
  header: #32292f,   //+
  background: #f2f4f3,  //+ 4B5857
  footer: #b1cacc,    //+ 4B5857
  
  //fonts
  font: #F0F7F4,   //+
  font-2: #32292f,   //+ F0F7F4
  link: #69554F,    //+
  link-2: #8998A9,
);

///// MIXINS

@mixin theme($palette) {
 

  body{

  color: map-get($palette, font)!important;
  background-color: map-get($palette, background) !important;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;

  }

  a {
    color: map-get($palette, link) !important;  
  }

  a:hover{
    color: map-get($palette, primary) !important;  
  }

  .header{
    color: map-get($palette, font) !important;
    background-color: map-get($palette, header) !important;
  }

  .container {
    background-color: map-get($palette, container) !important;
    color: map-get($palette, font) !important;
  }

  // .navbar {
  //   background-color: map-get($palette, label) !important;
  // }

  .dashboard {
    background-color: map-get($palette, header) !important;
   
  }

  .footer {
    background-color: map-get($palette, footer) !important;
    color: map-get($palette, font-2) !important;
  }



  .label{
    background-color: map-get($palette, label) !important;
    color: map-get($palette, secondary) !important;
  }

  .dashboard{
    background-color: map-get($palette, background) !important;
    color: map-get($palette, font) !important;
  }

  .primary{
    background-color: map-get($palette, primary) !important;
  }

  .tabs{
    .active{
      background-color: map-get($palette, container) !important;
    }

    .current{
      background-color: map-get($palette, container) !important;
    }
  }

  .fa, .fas, .far, .fal {
    color: map-get($palette, link) !important;
    
    :hover {
    color:map-get($palette, primary)
    }
  }

  .button{
    
    .primary{
      color: map-get($palette, primary) !important;
    }

  }
  .notification-stack {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  } 
  
  .notification {
  
  
    &.success {
      background-color: map-get($palette, primary) !important;
    }
  
    &.info {
      background-color: map-get($palette, label) !important;
    }
  
    &.fail {
      background-color: map-get($palette, footer) !important;
    }
  }

  h1,h2,h3,h4,h5,h6{
    color: map-get($palette, font-2) !important;
  }

  .title{
    color: map-get($palette, font-2) !important;
  }

  .font{  
    color: map-get($palette, font) !important;
  }
  .font-2{
    color: map-get($palette, font-2) !important;
  }

  .sidebar{
    background-color: map-get($palette, label) !important;
    color: map-get($palette, font) !important;
    .sibebar-item{
      background-color: map-get($palette, label) !important;
      color: map-get($palette, font) !important;
    }
    .sidebar-item:hover{
      background-color: map-get($palette, secondary) !important;
      color: map-get($palette, link-2) !important;
      border-color: map-get($palette, link-2) !important;
    }
    .active{
      background-color: map-get($palette, secondary) !important;
      color: map-get($palette, link-2) !important;
      border-color: map-get($palette, font-2) !important;
    }

  }

  .navigation{

border-width: 0;


    .navigation-item:hover{
      background-color: map-get($palette, secondary) !important;
      color: map-get($palette, font-2) !important;
    }

    .active{
      color: map-get($palette, primary) !important;
    }
  }

  .nav{
    border-width: 0px;
    border-color: map-get($palette, container) !important;
      // color: map-get($palette, font-2) !important;
  }

  .modal-container{
    background-color: map-get($palette, background) !important;
    color: map-get($palette, font-2) !important;

    .tabs{
      background-color: map-get($palette, background) !important;
    color: map-get($palette, font-2) !important;
    }

    .rich-editor{
      background-color: #ebebeb;
      color: #111213;
    }

  }

  .list-group {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column; 
    // max-width: 900px; /* Set the maximum width for the list group */
    // width: 100%; /* Ensure it takes up the full width of its container up to 600px */
    // margin: auto; /* Center the list group horizontally */
    padding: 10px !important;
    max-width: 900px;
    margin: auto;
    
    display: flex!important;
  
    img{
        width:65px;
        height:65px;
        border-radius:5px;
        object-fit: cover;
    }
  
  }
  
  .list-item {
    border-radius: 10px !important;
    width: 100%; /* Make each list item take the full width of the list group */
    margin-top: 1vh;
  }

  .list{
    //background-color: map-get($palette, container) !important;
    color: map-get($palette, font-2) !important;
    .item{
      background-color: map-get($palette, background) !important;
      color: map-get($palette, font-2) !important;
      border-color: map-get($palette, font-2) !important;
      box-shadow: 0 2px 4px map-get($palette, font-2);
      border-style: solid; border-width:1px;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
      }
    }
  }

  
  
  .list-item-container {
    border-radius: 8px;
    //box-shadow: 0 4px 8px map-get($palette, font-2);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }
  }
  
  .logo-container {
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  
  .list-group-item {
    border: none;
    background-color: transparent;
  }
  
  .actions-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  
    .action-icon {
      cursor: pointer;
      font-size: 1.2rem;
      &:hover {
        color: #007bff; /* Change the color on hover */
      }
    }
  }
  
  @media (max-width: 576px) {
    .list-item-container {
      flex-direction: column;
      text-align: center;
      padding: 1rem;
  
      .actions-container {
        flex-direction: row;
        margin-top: 10px;
      }
    }
  
    .logo-container img {
      width: 40px;
      height: 40px;
    }
  
    .list-group-item {
      padding: 0.75rem 1rem;
      font-size: 0.9rem;
    }
  }

  .action-icon{
    color: map-get($palette, font-2);
    
    :hover{ color: map-get($palette, primary)
    
    }
  }

  .lookerstudio-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #f0f0f0; /* Optional: background color while loading */
  }
  
  .lookerstudio-embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
}



body.tangerine-d {
  @include theme($palette-d-tangerine);
}

body.forest-d {
  @include theme($palette-d-forest);
}

body.arctic-d {
  @include theme($palette-d-arctic);
}

body.grape-d {
  @include theme($palette-d-grape);
}

body.peach-d {
  @include theme($palette-d-peach);
}

body.coconut-d {
  @include theme($palette-d-coconut);
}

body.tangerine-l {
  @include theme($palette-l-tangerine);
}

body.forest-l {
  @include theme($palette-l-forest);
}

body.arctic-l {
  @include theme($palette-l-arctic);
}

body.grape-l {
  @include theme($palette-l-grape);
}

body.peach-l {
  @include theme($palette-l-peach);
}

body.coconut-l {
  @include theme($palette-l-coconut);
}